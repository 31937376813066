// src/components/Intro.js
import React, { useEffect, useRef, useState } from 'react';
import introImg from './introimg.png'; // Ensure this path is correct

const Intro = () => {
  const [isVisible, setIsVisible] = useState(false);
  const introRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(introRef.current); // Stop observing after it has become visible
        }
      },
      { threshold: 0.1 } // Trigger when at least 10% of the element is visible
    );

    if (introRef.current) {
      observer.observe(introRef.current);
    }

    return () => {
      if (introRef.current) {
        observer.unobserve(introRef.current);
      }
    };
  }, []);

  return (
    <section
      id="app-intro"
      ref={introRef}
      className={`flex flex-col md:flex-row-reverse items-center justify-between p-6 md:p-12 bg-gradient-to-r from-purple-700 via-purple-800 to-purple-900 text-white transition-transform transform duration-700 ease-in-out ${isVisible ? 'animate-slide-left' : 'opacity-0'}`}
    >
      {/* Intro Image */}
      <div className="md:w-1/2 mb-8 md:mb-0">
        <img
          src={introImg}
          alt="Intro"
          className="w-3/4 h-auto rounded-lg shadow-lg transform scale-90 md:scale-95 transition-transform duration-500 ease-in-out hover:scale-105"
        />
      </div>

      {/* Intro Content */}
      <div className="md:w-1/2 text-center md:text-left">
        <h2 className="text-3xl md:text-4xl font-extrabold mb-4">
          Discover the Future of Communication
        </h2>
        <p className="text-lg mb-6">
          Our app offers innovative features to enhance your social interactions. From personalized AI experiences to engaging community events, we’re dedicated to bringing you the best.
        </p>
        <p className="text-lg mb-6">
          With EL TALK, you can connect with friends and family like never before. Enjoy real-time communication, exciting features, and a user-friendly interface that adapts to your needs. Explore new ways to engage with your community and make meaningful connections.
        </p>
        <p className="text-lg">
          Stay tuned for regular updates and new features designed to improve your experience. We’re committed to making your social interactions as enjoyable and seamless as possible.
        </p>
      </div>

      {/* Tailwind CSS for custom animation */}
      <style jsx>{`
        @keyframes slideLeft {
          0% { transform: translateX(100%); opacity: 0; }
          100% { transform: translateX(0); opacity: 1; }
        }
        .animate-slide-left {
          animation: slideLeft 0.7s ease-in-out;
        }
      `}</style>
    </section>
  );
};

export default Intro;
