// src/components/Footer.js
import React from 'react';
import appleStoreImg from './apple-store.jpg'; // Ensure this path is correct
import playStoreImg from './play-store.jpg'; // Ensure this path is correct

const Footer = () => {
  return (
    <footer className="bg-black text-white p-6 md:p-12">
      {/* Footer Content Container */}
      <div className="container mx-auto flex flex-col md:flex-row items-center justify-between">
        {/* Left Section: Footer Text and Social Media Links */}
        <div className="flex flex-col md:flex-row items-center justify-between text-center md:text-left mb-6 md:mb-0">
          <div className="mb-4 md:mb-0">
            <p className="text-lg mb-2">&copy; {new Date().getFullYear()} EL TALK. All rights reserved.</p>
            <p className="text-lg mb-4">
              Contact us at: <a href="mailto:support@eltalk.com" className="underline hover:text-yellow-400 transition duration-300">support@eltalk.com</a>
            </p>
          </div>
          <div className="text-sm flex flex-row items-center justify-center space-x-4">
            <p className="mb-0">Follow us on social media:</p>
            <a href="#" className="text-gray-400 hover:text-gray-300 transition duration-300">Facebook</a>
            <a href="#" className="text-gray-400 hover:text-gray-300 transition duration-300">Twitter</a>
            <a href="#" className="text-gray-400 hover:text-gray-300 transition duration-300">Instagram</a>
          </div>
        </div>

        {/* Right Section: App Store and Google Play Store Images */}
        <div className="flex flex-row items-center space-x-4">
          <a href="#apple-store" className="transition-transform transform hover:scale-105">
            <img src={appleStoreImg} alt="Apple Store" className="w-36 h-auto object-contain rounded-lg shadow-lg border border-white border-opacity-30" />
          </a>
          <a href="#play-store" className="transition-transform transform hover:scale-105">
            <img src={playStoreImg} alt="Play Store" className="w-36 h-auto object-contain rounded-lg shadow-lg border border-white border-opacity-30" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
