// src/components/Contact.js
import React, { useEffect, useRef, useState } from 'react';

const Contact = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target); // Stop observing after it's visible
        }
      },
      { threshold: 0.1 } // Trigger when 10% of the section is visible
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <section
      id="contact"
      ref={sectionRef}
      className={`p-6 md:p-12 bg-gradient-to-r from-purple-700 via-purple-800 to-purple-900 text-white  relative ${isVisible ? 'animate-slide-up' : 'opacity-0'}`}
    >
      {/* Inline styles for animations */}
      <style jsx>{`
        @keyframes slideUpFromBelow {
          from {
            transform: translateY(100%);
            opacity: 0;
          }
          to {
            transform: translateY(0);
            opacity: 1;
          }
        }

        .animate-slide-up {
          animation: slideUpFromBelow 0.7s ease-out forwards;
        }

        .contact-card {
          opacity: 0;
          transform: translateY(50%);
        }

        .contact-card.show {
          opacity: 1;
          transform: translateY(0);
          transition: opacity 0.6s ease-out, transform 0.6s ease-out;
        }

        .contact-form input,
        .contact-form textarea {
          transition: border-color 0.3s ease-in-out;
        }

        .contact-form input:focus,
        .contact-form textarea:focus {
          border-color: #4a90e2; /* Blue focus color */
          outline: none;
        }
      `}</style>

      <div className="container mx-auto">
        <div className="flex flex-col md:flex-row items-start md:items-center gap-12">
          {/* Contact Content */}
          <div className={`bg-blue-900 text-white p-8 rounded-lg shadow-lg w-full md:w-1/2 lg:w-2/5 ${isVisible ? 'show' : ''}`}>
            <h3 className="text-2xl font-semibold mb-6">Get in Touch</h3>
            <p className="mb-6">
              We’d love to hear from you! Whether you have questions, feedback, or just want to say hello, feel free to reach out to us.
            </p>
            <p className="mb-6">
              <strong>Address:</strong> 1234 Elm Street, Suite 567, Springfield, IL 62704
            </p>
            <p className="mb-6">
              <strong>Phone:</strong> (123) 456-7890
            </p>
            <p className="mb-6">
              <strong>Email:</strong> contact@eltalk.com
            </p>
          </div>

          {/* Contact Form */}
          <div className={`bg-white text-gray-900 p-8 rounded-lg shadow-lg w-full md:w-1/2 lg:w-3/5 ${isVisible ? 'show' : ''}`}>
            <h3 className="text-2xl font-semibold mb-6">Contact Form</h3>
            <form className="contact-form">
              <div className="mb-6">
                <label htmlFor="name" className="block text-lg mb-2">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="w-full p-3 border border-gray-300 rounded-lg focus:border-blue-500"
                  placeholder="Your Name"
                />
              </div>
              <div className="mb-6">
                <label htmlFor="email" className="block text-lg mb-2">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="w-full p-3 border border-gray-300 rounded-lg focus:border-blue-500"
                  placeholder="Your Email"
                />
              </div>
              <div className="mb-6">
                <label htmlFor="message" className="block text-lg mb-2">Message</label>
                <textarea
                  id="message"
                  name="message"
                  rows="4"
                  className="w-full p-3 border border-gray-300 rounded-lg focus:border-blue-500"
                  placeholder="Your Message"
                ></textarea>
              </div>
              <button
                type="submit"
                className="bg-blue-500 text-white py-3 px-6 rounded-lg hover:bg-blue-600 transition duration-300"
              >
                Send Message
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
