// src/App.js
import React from 'react';
import Hero from './components/Hero';
import Intro from './components/Intro';
import Features from './components/Features';
import Footer from './components/Footer';
import introImg from './components/heroimg.jpg'
import Contact from './components/Contact';

const App = () => {
  return (
    <div>
      <Hero />
      <Intro 
        imgSrc={introImg} 
        content={<p className="text-lg text-gray-600">Intro content goes here. Explain the main features and benefits of the app in a compelling way.</p>} 
      />
      <Features />
      <Contact></Contact>
      <Footer />
    </div>
  );
};

export default App;