// src/components/Features.js
import React, { useEffect, useRef, useState } from 'react';

// Sample data for features
const features = [
  {
    title: "Free Events",
    description: "Participate in engaging community events hosted for free. Connect and interact with other users in exciting ways.",
    icon: "🎉"
  },
  {
    title: "Cam with Filters",
    description: "Enjoy real-time camera filters similar to popular apps. Make your photos and videos more fun and engaging with creative effects.",
    icon: "📸"
  },
  {
    title: "AI Screening",
    description: "Leverage AI to monitor and filter content, ensuring a safe and enjoyable experience for all users.",
    icon: "🤖"
  },
  {
    title: "Clans (Groups)",
    description: "Join or create groups for various interests and activities. Engage with like-minded people and build your community.",
    icon: "👥"
  },
  {
    title: "AI Learning",
    description: "Access interactive AI-driven learning experiences. Improve your skills with personalized lessons and feedback.",
    icon: "📚"
  },
  {
    title: "Multi-Role Support",
    description: "Manage and participate in different roles within the app, including admin, community manager, and more.",
    icon: "🛠️"
  }
];

const Features = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target); // Stop observing after it's visible
        }
      },
      { threshold: 0.1 } // Trigger when 10% of the section is visible
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <section
      id="features"
      ref={sectionRef}
      className={`p-6 md:p-12 bg-gradient-to-r from-purple-700 via-purple-800 to-purple-900 text-white relative ${isVisible ? 'animate-slide-up' : 'opacity-0'}`}
    >
      {/* Inline styles for animations */}
      <style jsx>{`
        @keyframes slideUpFromBelow {
          from {
            transform: translateY(100%);
            opacity: 0;
          }
          to {
            transform: translateY(0);
            opacity: 1;
          }
        }

        .animate-slide-up {
          animation: slideUpFromBelow 0.7s ease-out forwards;
        }

        .feature-card {
          opacity: 0;
          transform: translateY(50%);
        }

        .feature-card.show {
          opacity: 1;
          transform: translateY(0);
          transition: opacity 0.6s ease-out, transform 0.6s ease-out;
        }

        .feature-card:hover {
          transform: scale(1.05);
          box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
        }
      `}</style>

      <div className="container mx-auto">
        <h2 className="text-3xl md:text-4xl font-extrabold text-center mb-12">
          Key Features
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <div
              key={index}
              className={`bg-white text-purple-900 p-6 rounded-lg shadow-lg transition-transform duration-300 ease-in-out feature-card ${
                isVisible ? 'show' : ''
              }`}
            >
              <div className="text-4xl mb-4">
                {feature.icon}
              </div>
              <h3 className="text-2xl font-semibold mb-2">
                {feature.title}
              </h3>
              <p className="text-lg text-gray-700">
                {feature.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;
