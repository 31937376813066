import React, { useState } from 'react';
import heroImg from './heroimg2.png';
import appleStoreImg from './apple-store.jpg';
import playStoreImg from './play-store.jpg';

const Hero = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <header className="relative bg-gradient-to-r from-purple-700 via-purple-800 to-purple-900 text-white overflow-hidden h-[100vh]">
      {/* Sidebar for small screens */}
      <div
        className={`fixed inset-0 bg-purple-800 text-white md:hidden ${
          isSidebarOpen ? 'block' : 'hidden'
        } transition-transform transform ${
          isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
        } z-50 flex flex-col items-center justify-center`}
      >
        <button onClick={toggleSidebar} className="text-3xl mb-6">&times;</button>
        <div className="flex flex-col space-y-4">
          <a href="#home" className="text-2xl font-semibold py-4 px-8 rounded-full bg-purple-600 hover:bg-purple-700 transition">Home</a>
          <a href="#app-intro" className="text-2xl font-semibold py-4 px-8 rounded-full bg-purple-600 hover:bg-purple-700 transition">App Intro</a>
          <a href="#contact-us" className="text-2xl font-semibold py-4 px-8 rounded-full bg-purple-600 hover:bg-purple-700 transition">Contact Us</a>
        </div>
      </div>

      {/* Navigation Bar for large screens */}
      <nav className="hidden md:flex items-center justify-center p-6 bg-transparent z-10 relative">
        <div className="text-3xl font-bold tracking-wide mr-auto">
          EL TALK
        </div>
        <div className="flex space-x-8">
          <a href="#home" className="text-2xl font-semibold px-6 py-3 rounded-full bg-purple-600 hover:bg-purple-700 transition">Home</a>
          <a href="#app-intro" className="text-2xl font-semibold px-6 py-3 rounded-full bg-purple-600 hover:bg-purple-700 transition">App Intro</a>
          <a href="#contact-us" className="text-2xl font-semibold px-6 py-3 rounded-full bg-purple-600 hover:bg-purple-700 transition">Contact Us</a>
        </div>
      </nav>

      {/* Mobile Navbar Toggle Button */}
      <div className="md:hidden flex justify-between items-center p-6 bg-transparent relative z-10">
        <div className="text-3xl font-bold">
          EL TALK
        </div>
        <button onClick={toggleSidebar} className="text-2xl">☰</button>
      </div>

      {/* Hero Section */}
      <section className="relative flex flex-col md:flex-row items-center justify-between p-8 md:p-16 lg:p-24 z-10 h-full">
        <div className="md:w-2/5 mt-8 md:mt-0 animate-slide-left relative">
          <img
            src={heroImg}
            alt="Hero"
            className="w-full max-w-[90%] h-auto rounded-lg shadow-lg transform hover:scale-105 transition"
          />
        </div>
        <div className="md:w-1/2 text-center md:text-left animate-slide-right space-y-6">
          <h1 className="text-5xl md:text-6xl font-extrabold leading-tight">
            Welcome to EL TALK
          </h1>
          <p className="text-lg md:text-xl leading-relaxed">
            Experience the best in social interaction with innovative features and a sleek, user-friendly design. Connect, share, and engage like never before.
          </p>
          <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
            <a href="#download" className="bg-yellow-500 text-purple-900 px-8 py-4 rounded-full font-semibold hover:bg-yellow-400 transition transform hover:scale-105">
              Download Now
            </a>
            <a href="#learn-more" className="bg-gray-800 text-white px-8 py-4 rounded-full font-semibold hover:bg-gray-700 transition transform hover:scale-105">
              Learn More
            </a>
          </div>
          <div className="flex flex-col md:flex-row items-center justify-center md:justify-start space-y-4 md:space-y-0 md:space-x-6 animate-slide-right mt-8">
            <a href="#apple-store">
              <img src={appleStoreImg} alt="Apple Store" className="w-40 h-auto object-contain transform hover:scale-105 transition" />
            </a>
            <a href="#play-store">
              <img src={playStoreImg} alt="Play Store" className="w-40 h-auto object-contain transform hover:scale-105 transition" />
            </a>
          </div>
        </div>
      </section>

      {/* Wave Background */}
      <div className="absolute bottom-0 left-0 right-0">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path fill="#6b46c1" fillOpacity="1" d="M0,224L48,186.7C96,149,192,75,288,85.3C384,96,480,192,576,229.3C672,267,768,245,864,218.7C960,192,1056,160,1152,160C1248,160,1344,192,1392,208L1440,224L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
        </svg>
      </div>
    </header>
  );
};

export default Hero;
